var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "confirm-count-wrap",
      attrs: {
        title: "复核",
        visible: _vm.centerDialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "70%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.centerDialogVisible = $event
        },
      },
    },
    [
      _c("section", { staticClass: "body" }, [
        _c("div", { staticClass: "body-header" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.formData.productName)),
            ]),
            _c("div", { staticClass: "count" }, [
              _vm._v(" 总计："),
              _c("span", [_vm._v(_vm._s(_vm.formData.originNumber))]),
            ]),
            _c("div", { staticClass: "lose" }, [
              _vm._v(" 缺货总计："),
              _c("span", [_vm._v(_vm._s(_vm.outStockNumber))]),
            ]),
          ]),
          _c("div", { staticClass: "subtitle" }, [
            _vm._v(
              " " +
                _vm._s(
                  `${_vm.formData.storeLogisticsName}-${_vm.formData.storeDeliveryName}`
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "form" }, [
            _c(
              "div",
              { staticClass: "input-wrap" },
              [
                _c("span", { staticClass: "label" }, [_vm._v("品名：")]),
                _c("el-input", {
                  attrs: { placeholder: "请输入", clearable: "" },
                  on: { change: _vm.onChangeFormData },
                  model: {
                    value: _vm.form.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "title", $$v)
                    },
                    expression: "form.title",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-wrap" },
              [
                _c("span", { staticClass: "label" }, [_vm._v("货号：")]),
                _c("el-input", {
                  attrs: { placeholder: "请输入", clearable: "" },
                  on: { change: _vm.onChangeFormData },
                  model: {
                    value: _vm.form.sku_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "sku_code", $$v)
                    },
                    expression: "form.sku_code",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-wrap right" },
              [
                _c(
                  "el-checkbox",
                  {
                    on: { change: _vm.onChangeAllOutStock },
                    model: {
                      value: _vm.allOutStock,
                      callback: function ($$v) {
                        _vm.allOutStock = $$v
                      },
                      expression: "allOutStock",
                    },
                  },
                  [_vm._v("整仓缺货")]
                ),
                _vm.allOutStock
                  ? _c(
                      "div",
                      { staticClass: "select-out-wrap" },
                      [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("缺货原因："),
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "select-wrap",
                            attrs: {
                              "popper-class": "select-popper-wrap",
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.allQcReason,
                              callback: function ($$v) {
                                _vm.allQcReason = $$v
                              },
                              expression: "allQcReason",
                            },
                          },
                          _vm._l(_vm.explainList, function (item) {
                            return _c(
                              "el-option",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.explainHideStatus.includes(
                                      item.id
                                    ),
                                    expression:
                                      "!explainHideStatus.includes(item.id)",
                                  },
                                ],
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "content",
                                    attrs: { title: item.name },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: {
                      size: "medium",
                      type: "primary",
                      disabled: !(_vm.allOutStock && _vm.allQcReason),
                    },
                    on: { click: _vm.onHandleAllOutStock },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "body-content" },
          [
            _c(
              "div",
              {
                staticClass: "table-wrap",
                class: { "axle-style": _vm.tableData.length > _vm.diff },
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "refElTable",
                    attrs: {
                      height: "100%",
                      data: _vm.tableData,
                      "row-class-name": _vm.unitClass,
                      "header-cell-style": {
                        color: "#333333",
                        background: "#EFF6FF",
                      },
                      border: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        type: "index",
                        width: "90",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "品名",
                        prop: "title",
                        minWidth: "150",
                        "show-overflow-tooltip": "",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "货号",
                        prop: "sku_code",
                        minWidth: "150",
                        "show-overflow-tooltip": "",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "应装车数量",
                        prop: "originNumber",
                        minWidth: "90",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "缺货数量",
                        minWidth: "100",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                staticClass: "number-wrap",
                                attrs: {
                                  placeholder: "请输入",
                                  disabled: _vm.disabled(scope.row.editStatus),
                                  clearable: "",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.onHandleChange(
                                      scope.$index,
                                      "qcNumber"
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.qcNumber,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "qcNumber", $$v)
                                  },
                                  expression: "scope.row.qcNumber",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "缺货原因",
                        minWidth: "120",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  staticClass: "select-wrap",
                                  attrs: {
                                    "popper-class": "select-popper-wrap",
                                    disabled: _vm.disabled(
                                      scope.row.editStatus
                                    ),
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  on: {
                                    change: (data) =>
                                      _vm.onChangeReason(
                                        scope.row.qcSkuInfoId,
                                        data
                                      ),
                                  },
                                  model: {
                                    value: scope.row.qcReason,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "qcReason", $$v)
                                    },
                                    expression: "scope.row.qcReason",
                                  },
                                },
                                _vm._l(_vm.explainList, function (item) {
                                  return _c(
                                    "el-option",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            !_vm.explainHideStatus.includes(
                                              item.id
                                            ),
                                          expression:
                                            "!explainHideStatus.includes(item.id)",
                                        },
                                      ],
                                      key: item.id,
                                      staticClass: "select-Item",
                                      attrs: {
                                        label: item.name,
                                        value: item.id,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "content",
                                          attrs: { title: item.name },
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "甩货数量",
                        minWidth: "100",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("el-input", {
                                staticClass: "number-wrap",
                                attrs: {
                                  placeholder: "请输入",
                                  disabled: _vm.disabled(scope.row.editStatus),
                                  clearable: "",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.onHandleChange(
                                      scope.$index,
                                      "throwNumber"
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.throwNumber,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "throwNumber", $$v)
                                  },
                                  expression: "scope.row.throwNumber",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "实际装车数量",
                        prop: "realTransportNumber",
                        minWidth: "100",
                        align: "center",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("axle-pagination", {
              ref: "refCount",
              attrs: {
                tableList: _vm.tableData,
                unitClass: _vm.unitClass,
                diff: _vm.diff,
              },
              on: { emitScrollChange: _vm.onHandleScrollChange },
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.centerDialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("out-stock-reason-dialog", {
        ref: "refOutStockReason",
        on: { "on-edit-reason": _vm.onEditReason },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }