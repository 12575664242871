<template>
  <el-dialog
    title="复核"
    :visible.sync="centerDialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="70%"
    class="confirm-count-wrap"
    center
  >
    <section class="body">
      <div class="body-header">
        <!-- 品类信息 start -->
        <div class="title">
          <div class="name">{{ formData.productName }}</div>
          <div class="count">
            总计：<span>{{ formData.originNumber }}</span>
          </div>
          <div class="lose">
            <!-- formData.outStockNumber -->
            缺货总计：<span>{{ outStockNumber }}</span>
          </div>
        </div>
        <div class="subtitle">
          {{ `${formData.storeLogisticsName}-${formData.storeDeliveryName}` }}
          <!-- <div>{{ `等级：${formData.productGrade}` }}</div>
          <div>{{ `货号：${formData.productSkuCode}` }}</div>
          <div>{{ `规格：${formData.productSpec}` }}</div> -->
        </div>
        <!-- 品类信息 end -->

        <!-- 筛选项 start -->
        <div class="form">
          <div class="input-wrap">
            <span class="label">品名：</span>
            <el-input
              placeholder="请输入"
              v-model="form.title"
              @change="onChangeFormData"
              clearable
            >
            </el-input>
          </div>
          <div class="input-wrap">
            <span class="label">货号：</span>
            <el-input
              placeholder="请输入"
              v-model="form.sku_code"
              @change="onChangeFormData"
              clearable
            >
            </el-input>
          </div>
          <!-- 整仓缺货 start -->
          <div class="input-wrap right">
            <el-checkbox v-model="allOutStock" @change="onChangeAllOutStock"
              >整仓缺货</el-checkbox
            >
            <div class="select-out-wrap" v-if="allOutStock">
              <span class="label">缺货原因：</span>
              <el-select
                class="select-wrap"
                popper-class="select-popper-wrap"
                v-model="allQcReason"
                clearable
                placeholder="请选择"
              >
                <!-- v-show="item.id !== explainHideStatus" -->
                <el-option
                  v-for="item in explainList"
                  :key="item.id"
                  v-show="!explainHideStatus.includes(item.id)"
                  :label="item.name"
                  :value="item.id"
                >
                  <div :title="item.name" class="content">
                    {{ item.name }}
                  </div>
                </el-option>
              </el-select>
            </div>
            <el-button
              size="medium"
              type="primary"
              :disabled="!(allOutStock && allQcReason)"
              @click="onHandleAllOutStock"
              >确认</el-button
            >
          </div>
          <!-- 整仓缺货 end -->
        </div>
        <!-- 筛选项 end -->
      </div>
      <div class="body-content">
        <!-- 列表 start -->
        <div
          class="table-wrap"
          :class="{ 'axle-style': tableData.length > diff }"
        >
          <el-table
            height="100%"
            :data="tableData"
            :row-class-name="unitClass"
            v-loading="loading"
            ref="refElTable"
            :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
            border
          >
            <el-table-column
              label="序号"
              type="index"
              width="90"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="品名"
              prop="title"
              minWidth="150"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="货号"
              prop="sku_code"
              minWidth="150"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column
              label="城市仓"
              prop="storeLogisticsName"
              minWidth="150"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="自提点名称"
              prop="storeDeliveryName"
              minWidth="150"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column> -->
            <el-table-column
              label="应装车数量"
              prop="originNumber"
              minWidth="90"
              align="center"
            >
            </el-table-column>
            <el-table-column label="缺货数量" minWidth="100" align="center">
              <template slot-scope="scope">
                <el-input
                  placeholder="请输入"
                  class="number-wrap"
                  v-model="scope.row.qcNumber"
                  :disabled="disabled(scope.row.editStatus)"
                  @blur="onHandleChange(scope.$index, 'qcNumber')"
                  clearable
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="缺货原因" minWidth="120" align="center">
              <template slot-scope="scope">
                <el-select
                  class="select-wrap"
                  popper-class="select-popper-wrap"
                  v-model="scope.row.qcReason"
                  :disabled="disabled(scope.row.editStatus)"
                  @change="
                    (data) => onChangeReason(scope.row.qcSkuInfoId, data)
                  "
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    class="select-Item"
                    v-for="item in explainList"
                    :key="item.id"
                    v-show="!explainHideStatus.includes(item.id)"
                    :label="item.name"
                    :value="item.id"
                  >
                    <div :title="item.name" class="content">
                      {{ item.name }}
                    </div>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="甩货数量" minWidth="100" align="center">
              <template slot-scope="scope">
                <el-input
                  placeholder="请输入"
                  class="number-wrap"
                  v-model="scope.row.throwNumber"
                  :disabled="disabled(scope.row.editStatus)"
                  @blur="onHandleChange(scope.$index, 'throwNumber')"
                  clearable
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="实际装车数量"
              prop="realTransportNumber"
              minWidth="100"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <!-- 列表 end -->

        <!--滚动轴分页 start -->
        <axle-pagination
          ref="refCount"
          :tableList="tableData"
          :unitClass="unitClass"
          :diff="diff"
          @emitScrollChange="onHandleScrollChange"
        >
        </axle-pagination>
        <!-- 滚动轴分页 end -->
      </div>
    </section>
    <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="onHandleSubmit">确 定</el-button>
    </span>

    <!-- 品类缺货原因 start -->
    <out-stock-reason-dialog
      ref="refOutStockReason"
      @on-edit-reason="onEditReason"
    ></out-stock-reason-dialog>
    <!-- 品类缺货原因 end -->
  </el-dialog>
</template>

<script>
import AxlePagination from "@/views/logistics/sheet-to-review/components/AxlePagination";
import OutStockReasonDialog from "../../modules/out-stock-reason-dialog";
// import mock from "@/views/logistics/sheet-to-review/mock";
// import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import tool from "@/utils/tools/tool";
import {
  getGoodsSheetReviewSingleList,
  postGoodsSheetSingleReview,
  getGoodsSheetQCExplainListAll,
} from "@/api/sheet-to-review/index";
import {
  EDIT_STATUS_MAP,
  OUT_STOCK_REASON_ENUM,
} from "@/views/logistics/utils/enum";

export default {
  name: "ConfirmCountDialog",
  components: {
    AxlePagination,
    OutStockReasonDialog,
  },
  data() {
    return {
      centerDialogVisible: false,
      formData: {},
      // 表单查询项
      form: {
        logisticsId: [],
        deliveryId: [],
        title: "",
        sku_code: "",
      },
      // 城市仓列表
      cityStoreList: [],
      explainHideStatus: [
        OUT_STOCK_REASON_ENUM.QC_OUT_STOCK.value,
        OUT_STOCK_REASON_ENUM.OUT_STOCK.value,
      ], // 隐藏的缺货原因枚举
      // 自提点列表
      selfTakeList: [],
      unitClass: "count-box",
      loading: false,
      allOutStock: false, // 是否整仓缺货
      allQcReason: null, // 整仓缺货原因
      tableData: [],
      tableDataClone: [], // 原始数据
      // 视图内数据量
      diff: 10,
      // 缺货原因列表
      explainList: [],
      reasonObj: {}, // 当前选择的缺货单元数据
    };
  },
  computed: {
    /**
     * 实时计算总缺货数
     */
    outStockNumber() {
      let res = 0;
      this.tableDataClone.forEach((item) => {
        res += parseInt(item.qcNumber);
      });
      return res || 0;
    },
    /**
     * 是否禁用编辑框
     */
    disabled() {
      return (data) => {
        const res = data === EDIT_STATUS_MAP.readonly.value;
        return res;
      };
    },
  },
  updated() {
    this.$refs.refElTable?.bodyWrapper.addEventListener(
      "scroll",
      this.addEventScroll,
      true
    );
    // console.log("🆒 updated", tool);
  },
  destroyed() {
    this.$refs.refElTable?.bodyWrapper.removeEventListener("scroll");
  },
  methods: {
    /**
     * 内容初始化
     */
    async initShow(row) {
      this.loading = true;
      this.onRestData();
      this.allOutStock = false;
      this.allQcReason = null;
      this.centerDialogVisible = true;
      this.formData = row;
      // this.getAjaxCityStoreList();
      await this.getAjaxExplainList();
      this.getAjaxConfirmData();
      // console.log("🆒 onHandleReview row", row, this.$refs.refElTable);
    },
    /**
     * 整仓缺货状态变化
     */
    onChangeAllOutStock(data) {
      // 初始化一遍
      if (data) {
        this.loading = true;
        this.onRestData();
        this.onChangeFormData();
        this.loading = false;
      }
    },
    /**
     * 重置数据
     */
    onRestData() {
      // 重置条件
      // this.form.logisticsId = [];
      // this.form.deliveryId = [];
      this.form.title = "";
      this.form.sku_code = "";
      this.tableData = [];
    },
    /**
     * 修改了整仓缺货原因
     */
    onHandleAllOutStock() {
      // 如果当前选择的是其他原因，则需输入原因
      if (this.allQcReason === OUT_STOCK_REASON_ENUM.OTHER.value) {
        // this.$emit('on-show-reason');
        this.$refs.refOutStockReason.onInitData(true);
      } else {
        this.setAllQcReason();
      }
    },
    /**
     * 修改了单个缺货原因
     */
    onChangeReason(id, type) {
      console.log("🆒 onChangeReason 666", id, type);
      this.reasonObj = {};
      // 查找当前两组数据下标值，
      const index = this.tableDataClone.findIndex(
        (item) => item.qcSkuInfoId === id
      );
      const currentIndex = this.tableData.findIndex(
        (item) => item.qcSkuInfoId === id
      );
      console.log("🆒 onChangeReason 888", index, currentIndex);
      // 当为其他时，需提交说明
      if (type === OUT_STOCK_REASON_ENUM.OTHER.value) {
        this.$refs.refOutStockReason.onInitData();
        // console.log("🆒 onChangeReason 999", id, index, currentIndex);
        // 恢复为初始化原因
        this.tableData[currentIndex].qcReason =
          this.tableDataClone[index].qcReason;
        // 将当前原因存储
        this.reasonObj = { id, type };
      } else {
        // 非其他，则需同步两组数据
        this.tableDataClone[index].qcReason = type;
      }
    },
    /**
     * 提交了缺货原因
     */
    onEditReason(reason, bool) {
      // console.log("🆒 onEditReason", reason, bool, this.reasonObj);
      // 选择了全部缺货
      if (bool) {
        this.setAllQcReason(reason);
      } else {
        // 选择了单个缺货
        const { id, type } = this.reasonObj;
        const index = this.tableDataClone.findIndex(
          (item) => item.qcSkuInfoId === id
        );
        const currentIndex = this.tableData.findIndex(
          (item) => item.qcSkuInfoId === id
        );
        this.tableData[currentIndex].qcReason = type;
        this.tableDataClone[index].qcReason = type;
        this.tableData[currentIndex].qcReasonDesc = reason;
        this.tableDataClone[index].qcReasonDesc = reason;
        // console.log("🆒 onEditReason 666666", currentIndex, index, reason);
      }
    },
    /**
     * 设置所有的缺货原因
     */
    setAllQcReason(reason) {
      this.tableDataClone.forEach((item) => {
        // 非只读情况
        if (item.editStatus !== EDIT_STATUS_MAP.readonly.value) {
          item.qcNumber = item.originNumber;
          item.qcReason = this.allQcReason;
          // 原因为其他时，需补充原因
          item.qcReasonDesc = reason || "";
          item.throwNumber = null;
          item.realTransportNumber = 0;
          const index = this.tableDataClone.findIndex(
            (child) => child.qcSkuInfoId === item.qcSkuInfoId
          );
          // console.log("🆒 setAllQcReason 99999999", this.tableData, index);
          this.tableData[index].qcReason = this.allQcReason;
        }
      });
    },
    /**
     * 复核单三期，隐藏
     * 获取城市仓列表
     */
    // getAjaxCityStoreList: debounce(async function (data) {
    //   const params = {
    //     name: data,
    //     page: 1,
    //     pageSize: 1000,
    //   };
    //   try {
    //     const { data } = await this.$api.logistics.entruckLogisticslist(params);
    //     this.cityStoreList = data.data;
    //     console.log("ajx getAjaxCityStoreList", data.data);
    //   } catch (err) {
    //     console.log("ajx getAjaxCityStoreList err", err);
    //   }
    // }, 300),
    /**
     * 获取缺货原因列表
     */
    async getAjaxExplainList() {
      try {
        const { data } = await getGoodsSheetQCExplainListAll();
        this.explainList = data;
        console.log("ajax getGoodsSheetQCExplainListAll", data);
      } catch (err) {
        console.log("ajax getGoodsSheetQCExplainListAll err", err);
      }
    },
    /** 复核单三期，隐藏 */
    /**
     * 获取自提点列表
     */
    // getAjaxSelfTakeList: debounce(async function ({
    //   name = "",
    //   logisticsId = [],
    // }) {
    //   /**
    //    * 城市仓为必传参数
    //    * 防止接口在删除城市仓前请求数据，故先判断条件
    //    */
    //   if (!logisticsId?.length || !this.form.logisticsId.length) {
    //     return;
    //   }
    //   const params = {
    //     name,
    //     logistics_id: logisticsId,
    //     page: 1,
    //     pageSize: 1000,
    //   };
    //   try {
    //     const { data } = await this.$api.logistics.entruckDeliverylist(params);
    //     this.selfTakeList = data.data;
    //     console.log("ajx getAjaxSelfTakeList", data.data);
    //   } catch (err) {
    //     console.log("ajx getAjaxSelfTakeList err", err);
    //   }
    // },
    // 300),
    // /**
    //  * 城市仓发生变化
    //  */
    // onChangeCityStore(data) {
    //   console.log("🆒 onChangeCityStore 666666666", data);
    //   // 重置自提点
    //   this.selfTakeList = [];
    //   this.form.deliveryId = [];
    //   if (data?.length) {
    //     const params = {
    //       name: "",
    //       logisticsId: data,
    //     };
    //     this.getAjaxSelfTakeList(params);
    //   }
    //   this.getAjaxConfirmData();
    // },
    // /**
    //  * 自提点发生变化
    //  */
    // onChangeSelfTake(data) {
    //   console.log("onChangeSelfTake data", data);
    //   this.getAjaxConfirmData();
    // },
    // /**
    //  * 搜索数据项
    //  */
    // onFilterMethod(type, data) {
    //   if (type === "cityStore") {
    //     this.getAjaxCityStoreList(data);
    //   }
    //   if (type === "selfTake") {
    //     const params = {
    //       name: data,
    //       logisticsId: this.form.logisticsId,
    //     };
    //     this.getAjaxSelfTakeList(params);
    //   }
    // },
    // /**
    //  * 失焦时，初始化下拉框
    //  */
    // onChangeVisible(type, data) {
    //   if (!data && type === "cityStore") {
    //     this.getAjaxCityStoreList();
    //   }
    //   if (!data && type === "selfTake") {
    //     const params = {
    //       name: "",
    //       logisticsId: this.form.logisticsId,
    //     };
    //     this.getAjaxSelfTakeList(params);
    //   }
    // },
    /**
     * 修改了品名、货号（前端过滤数据，不再请求接口）
     */
    onChangeFormData() {
      const list = cloneDeep(this.tableDataClone);
      // 不存在条件，展示全量
      if (!this.form.title && !this.form.sku_code) {
        this.tableData = list;
        return;
      }
      // 存在条件，则筛选数据
      this.tableData = list.filter((item) => {
        const hasTitle =
          this.form.title && item.title.indexOf(this.form.title) !== -1;
        const hasSn =
          this.form.sku_code &&
          item.sku_code.indexOf(this.form.sku_code) !== -1;
        if (this.form.title && this.form.sku_code) {
          return hasTitle && hasSn;
        } else {
          return hasTitle || hasSn;
        }
      });
      // this.getAjaxConfirmData();
    },
    // onChangeFormData: debounce(async function () {
    //   this.getAjaxConfirmData();
    // }, 100),
    /**
     * 获取复核列表
     */
    async getAjaxConfirmData() {
      // 组合接口需要的参数
      const { supplyOrderId, storeDeliveryId, supplyOrderDetailId } =
        this.formData;
      const { logisticsId: storeLogisticsIds, title, sku_code } = this.form;
      const params = {
        supplyOrderId,
        supplyOrderDetailId,
        storeLogisticsIds,
        storeDeliveryId,
        title,
        sku_code,
      };
      try {
        const { data } = await getGoodsSheetReviewSingleList(params);
        /** 此处逻辑废弃，后端将所有缺货原因下拉枚举返前端，无需改值 */
        // let list = data.map((item) => {
        //   const options = {
        //     ...item,
        //   };
        //   // 判断是否存在该枚举，如果不存在，则默认字符串原因，并保留原枚举
        //   let exist = this.explainList.find(
        //     (option) => option.id === item.qcReason
        //   );
        //   if (!exist?.name) {
        //     // 新建字符串，保留原枚举
        //     options.qcReasonOrigin = item.qcReason;
        //     options.qcReason = item.qcReasonString;
        //   }
        //   // console.log("🆒 getAjaxConfirmData", exist);
        //   return options;
        // });
        // console.log("🆒 getAjaxConfirmData", list);
        data.forEach((item) => {
          // 初始化不缺货的商品
          item.qcReason = item.qcReason || null;
        });
        this.tableData = data;
        this.tableDataClone = cloneDeep(data);
        console.log("ajax getGoodsSheetReviewSingleList", data);
      } catch (err) {
        console.log("ajax getGoodsSheetReviewSingleList err", err);
      } finally {
        this.loading = false;
      }
    },
    /**
     * 监听table滚动
     */
    addEventScroll(e) {
      // const { scrollTop, scrollHeight, clientHeight } = e.target;
      // console.log("🆒 addEventScroll e", scrollTop, scrollHeight, clientHeight);
      this.$refs.refCount.handScroll(e);
    },
    /**
     * 滚动数据
     */
    onHandleScrollChange(index) {
      const rowNode = document.querySelectorAll(`.${this.unitClass}`)?.[0];
      const tableNode = this.$refs.refElTable?.bodyWrapper;
      // tableNode.scrollTop = index * (rowNode?.clientHeight || 45);
      // 设置定时器，让其平滑滚动
      let step = 0;
      let time = setInterval(() => {
        tableNode.scrollTop = step * (rowNode?.clientHeight || 45);
        // 达到最大高度，停止计时器
        if (step >= index) {
          clearInterval(time);
        }
        step++;
      }, 20);
      // console.log("🆒 onHandleScrollChange", index, rowNode);
    },
    /**
     * 修改缺货数、甩货数
     * 1. 实际装 = 应装 - 缺货 - 甩货
     * 2. 实际装 >= 0
     */
    onHandleChange(index, val) {
      // const mustCount = this.tableData[index]["originNumber"];
      // 校验并输出值
      const data = this.tableData[index][val];
      const num = tool.filterUnNumber(data);
      // 最大不得超过 应装
      let res = num || data;
      /** 此处校验废弃，在提交时再处理 */
      // 得到其他关联数据
      // let key = val === "qcNumber" ? "throwNumber" : "qcNumber";
      // let other = this.tableData[index][key];
      // let must = Number(res) + Number(other) <= mustCount;
      // // console.log("🆒 onHandleChange", other, res, must);
      // // 判断是否符合条件
      // if (!must) {
      //   let msg = `第${index + 1}行：缺货、甩货数之和应小于应装车数！`;
      //   this.$message.error(msg);
      //   // 重置
      //   this.$set(this.tableData[index], [val], 0);
      //   return;
      // }
      // console.log("🆒 onHandleChange res", data, res);
      this.$set(this.tableData[index], [val], res);
      // 修改原始数据
      const originIndex = this.tableDataClone.findIndex(
        (item) => item.qcSkuInfoId === this.tableData[index].qcSkuInfoId
      );
      this.tableDataClone[originIndex][val] = res;
      // this.$set(this.tableDataClone[originIndex], [val], res);
      // 同时更新相关数据
      // 实际数量 = 应装 - 缺货 - 甩货
      let current =
        this.tableData[index]["originNumber"] -
        this.tableData[index]["qcNumber"] -
        this.tableData[index]["throwNumber"];
      // 符合条件，默认应装车数量
      if (current >= 0) {
        this.$set(this.tableData[index], "realTransportNumber", current);
        this.tableDataClone[originIndex]["realTransportNumber"] = current;
      } else {
        // let msg = `第${index + 1}行：缺货、甩货数之和应小于应装车数！`;
        // this.$message.error(msg);
      }
    },
    /**
     * 确定数量
     */
    async onHandleSubmit() {
      this.form.title = "";
      this.form.sku_code = "";
      this.tableData = cloneDeep(this.tableDataClone);
      // if (!this.tableData?.length) {
      //   this.$message.warning("没有数据需要提交~");
      //   return;
      // }
      // 组合接口需要的参数
      const { supplyOrderId } = this.formData;
      const params = {
        supplyOrderId,
        // supplyOrderDetailId,
        qcList: [],
      };
      try {
        // 遍历列表，输出接口需要的参数
        const list = this.tableData?.map((item, index) => {
          const {
            editStatus,
            originNumber,
            qcSkuInfoId,
            qcNumber,
            throwNumber,
            qcReason,
            qcReasonDesc,
          } = item;
          // 实际数量 = 应装 - 缺货 - 甩货
          let current =
            Number(originNumber) - Number(qcNumber) - Number(throwNumber);
          // 校验应装车数，拦截请求
          if (current < 0) {
            let msg = `第${index + 1}行：缺货、甩货数之和应小于应装车数！`;
            this.$message.error(msg);
            // 终止map
            throw new Error(msg);
          }
          // 校验缺货原因
          if (Number(qcNumber) && !qcReason) {
            let msg = `第${index + 1}行：请选择缺货原因！`;
            this.$message.error(msg);
            throw new Error(msg);
          }
          const options = {
            editStatus,
            qcSkuInfoId,
            qcNumber: qcNumber || "0",
            throwNumber: throwNumber || "0",
            qcReasonDesc: "",
          };
          // 当为其他原因，则增加缺货原因
          if (qcReason === OUT_STOCK_REASON_ENUM.OTHER.value) {
            options.qcReasonDesc = qcReasonDesc;
          }
          // 当存在缺货数量，且有缺货原因时，赋值
          if (Number(qcNumber) && qcReason) {
            options.qcReason = qcReason;
          }
          return options;
        });
        // 将不可编辑的数据筛掉
        const lastList = [];
        list.forEach((child) => {
          if (child.editStatus !== EDIT_STATUS_MAP.readonly.value) {
            const options = {
              ...child,
            };
            delete options.editStatus;
            lastList.push(options);
          }
        });
        // 去除状态参数
        params.qcList = lastList;
        // 列表提交数据为空，则无需请求接口
        if (!lastList?.length) {
          this.$message.warning("已全部完成复核，无需重复操作~");
          return;
        }
        console.log("🆒 onHandleSubmit", params);
        try {
          await postGoodsSheetSingleReview(params);
          this.$message.success("复核成功~");
          this.$emit("on-change-single", this.formData.supplyOrderNo);
          this.centerDialogVisible = false;
        } catch (err) {
          console.log("ajax postGoodsSheetSingleReview err", err);
        }
      } catch (err) {
        console.log("onHandleSubmit err", err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border: 1px solid #ebeef5;
.select-Item {
  margin: 10px 0px !important;
  font-weight: bold !important;
}

.confirm-count-wrap {
  /deep/ .el-dialog__body {
    padding: 0 20px;
  }
  .body {
    padding-left: 60px;
    &-header {
      width: calc(100% - 150px);
      padding-bottom: 14px;
      .title,
      .subtitle {
        display: flex;
        gap: 80px;
        font-size: 16px;
        font-weight: 600;
        color: $font-color;
      }
      .subtitle {
        padding-top: 20px;
      }
      .name {
        flex: 1;
        font-weight: 600;
      }
      .lose {
        span {
          color: #fa884c;
        }
      }
      .form {
        display: flex;
        gap: 30px;
        padding-top: 24px;
        .input-wrap {
          display: flex;
          .label {
            display: inline-block;
            // width: 80px;
            height: 36px;
            line-height: 40px;
          }
          /deep/ .el-input {
            width: 200px;
            height: 36px;
          }
          &.right {
            position: relative;
            left: 70px;
            gap: 30px;
            .el-checkbox {
              height: 36px;
              line-height: 40px;
            }
            .el-select {
              width: 200px;
            }
          }
        }
        // /deep/ .el-select {
        //   width: 240px;
        //   height: 36px;
        //   &__tags {
        //     flex-wrap: nowrap;
        //     width: calc(100% - 100px) !important;
        //     max-width: 170px !important;
        //   }
        //   .el-input {
        //     width: 100%;
        //     height: 100%;
        //     &__inner {
        //       width: 100%;
        //       height: 100% !important;
        //     }
        //   }
        // }
      }
    }
    &-content {
      display: flex;
      width: 100%;
      height: 600px;
      // padding-top: 14px;
      // padding-left: 60px;
      box-sizing: border-box;
      // width: calc(100% - 60px);
      .table-wrap {
        // flex: 1;
        width: calc(100% - 120px);
        padding-right: 20px;
        /** 输入框样式 */
        .number-wrap {
          height: 30px;
          /deep/ .el-input {
            &__inner {
              height: 100%;
            }
            &__suffix {
              &-inner {
                position: relative;
                bottom: 5px;
              }
            }
          }
        }
        /** 下拉选择框样式 */
        .select-wrap {
          height: 30px;
          /deep/ .el-input {
            height: 100%;
            line-height: 30px;
            &__inner {
              height: 100%;
            }
            &__suffix {
              .el-icon-arrow-up {
                &:before {
                  position: relative;
                  top: -3px;
                }
                &.is-reverse {
                  &:before {
                    position: relative;
                    top: -6px;
                  }
                }
              }
              .el-select__caret {
                &:before {
                  position: relative;
                  top: -3px;
                }
              }
            }
          }
        }
        /deep/ .el-table {
          &--border {
            border-top: $border;
            // border-right: $border;
            border-bottom: $border;
          }
        }
        /** 存在自定义滚动轴，样式需要变更 */
        &.axle-style {
          /deep/ .el-table {
            // width: calc(100% - 5px);
            width: 100%;
            height: 100%;
            &--border {
              border-right: none;
              // border-bottom: $border;
              &:before,
              &:after {
                // width: 0 !important;
                display: none;
              }
              th.el-table__cell.gutter:last-of-type {
                border: none;
              }
            }
            .has-gutter {
              .gutter {
                width: 0 !important;
              }
            }
            &__body {
              width: 100% !important;
              &-wrapper {
                // width: calc(100% - 5px);
                width: 100%;
                overflow: scroll;
                overflow-y: scroll;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
            tbody tr {
              &:last-child {
                td.el-table__cell {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
/** 下拉选择模态框样式 */
.select-popper-wrap {
  .el-select-dropdown {
    &__item {
      .content {
        max-width: 200px;
        @include ellipsis-lines;
      }
    }
  }
}
</style>
